<!--
 * @Author: 张博洋
 * @Date: 2022-02-21 14:50:28
 * @LastEditTime: 2022-02-23 17:34:29
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/follow/fill.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="wrap">
    <follow-form class="form-wrap"
                 :detail="detail"></follow-form>
  </div>
</template>

<script>
import FollowForm from './views/form.vue'
export default {
  components: {
    FollowForm,
  },
  data() {
    return {
      detail: {},
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$axios({
        type: 'get',
        url: 'visitTask/queryVisitForm',
        data: {
          taskId: this.$route.query.taskId,
        },
        elseData: {
          ajaxLoading: true,
          loading: true,
          errorTip: false,
        },
      }).then((res) => {
        console.log(res)
        this.detail = res.d
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .form-wrap {
    flex: 1;
    overflow-y: auto;
  }
}
</style>

